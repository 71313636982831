import countries from "@/countries.json";
export const countryStateMixin = {
  data() {
    return {
      countries: countries,
    };
  },
  methods: {
    getCountries() {
      return ["", ...this.countries.map((c) => c.countryName)];
    },
    getStates(country) {
      let states = [""];
      if (country) {
        states = [
          "",
          ...this.countries
            .filter((c) => country === c.countryName)[0]
            .regions.map((r) => r.name),
        ];
      }
      return states;
    },
    getStateAbbrv(country, state) {
      let stateAbbrv = "";
      if (country && state) {
        stateAbbrv = this.countries
          .filter((s) => country === s.countryName)[0]
          .regions.filter((r) => state === r.name)[0].shortCode;
      }
      return stateAbbrv;
    },
  },
};
export default countryStateMixin;
