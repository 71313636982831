<template>
  <div class="container">
    <div class="container content">
      <!-- <v-alert
        text
        class="gepi-alert"
        color="cyan darken-2"
        icon="mdi-alert-circle"
      >
        The data in GEPI.io is currently incomplete. We will integrate
        additional data sources and build new GEPI.io features as the Centers
        continue to reimagine internal data standards and processes.
      </v-alert> -->
      <div class="search-type">
        <div class="organization-icon-box">
          <div class="organization-icon"></div>
        </div>
        <h2>Organizations</h2>
      </div>
      <organizations-table
        :searchFilter="true"
        :typeFilter="true"
        :countryFilter="true"
        :projectsFilter="true"
        :showOnlyProjects="true"
        :partnersFilter="true"
      >
      </organizations-table>
    </div>
  </div>
</template>
<style>
/* Page CSS */
.organization-icon {
  height: 55px;
  width: 52px;
  margin: auto;
  padding: 10px;
  background-color: white;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("../assets/icons/organizations.svg");
}
.organization-icon-box {
  margin: 15px 10px;
  border-radius: 10px;
  height: 70px;
  width: 70px;
  padding-top: 5px;
  max-height: 140px;
  max-width: 140px;
  background-color: var(--aqua);
}
#search {
  position: relative;
  top: -14px;
}
.input-box {
  width: 90%;
}
label[for="search"] {
  height: 25px;
}
</style>
<script>
import OrganizationsTable from "../components/OrganizationsTable";
export default {
  components: {
    OrganizationsTable,
  },
  title() {
    return `Organizations - GEPI.io`;
  },
  data() {
    return {};
  },
};
</script>
