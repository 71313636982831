<template>
<div>
<Organizations></Organizations>
<People></People>
</div>
</template>
<style>
/* Page CSS */


</style>
<script>

import axios from 'axios';
import People from './People'
import Organizations from './Organizations'

export default {
    components: {
          People,
          Organizations
        },
    title () {
          return `GEPI: Contacts`
        },
        data() {
        return {
        
        }
    },
}

</script>
